/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"

const MobileThumb = ({ background, size }) => (
  <div
    css={css`
      position: relative;
      display: inline-block;
      margin-right: ${size === "large" ? "" : "20px"};
      width: ${size === "large" ? "300px" : "200px"};
      height: ${size === "large" ? "520px" : "390px"};
      border-radius: 0.5rem;
      background-color: #fff;
      -webkit-box-shadow: -2px 0px 20px 5px rgba(209, 209, 209, 0.3);
      -moz-box-shadow: -2px 0px 20px 5px rgba(209, 209, 209, 0.3);
      box-shadow: -2px 0px 20px 5px rgba(209, 209, 209, 0.3);
      @media (max-width: 1200px) {
        width: 140px;
        height: 290px;
      }
    `}
  >
    <div
      css={css`
        position: relative;
        width: 100%;
        height: ${size === "large" ? "420px" : "310px"};
        background-image: url(${background});
        background-size: cover;
        top: 30px;
        background-color: #ccc;
        @media (max-width: 1200px) {
          height: 210px;
        }
      `}
    />
    <div
      css={css`
        position: relative;
        width: 30px;
        height: 30px;
        top: 40px;
        border-radius: 20px;
        margin: 0 auto;
        -webkit-box-shadow: inset -1px 0px 2px 2px rgba(209, 209, 209, 0.3);
        -moz-box-shadow: inset -1px 0px 2px 2px rgba(209, 209, 209, 0.3);
        box-shadow: inset -1px 0px 2px 2px rgba(209, 209, 209, 0.3);
      `}
    ></div>
  </div>
)

export default MobileThumb
