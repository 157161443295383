/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import React from "react"
import { Button, Container, Row, Col } from "reactstrap"
import AngleCover from "./angleCover"

const UXContentContainer = ({
  image,
  header,
  body,
  bgcolor1 = "#f1f1f1",
  bgcolor2 = bgcolor1,
  rotation,
  bottomAngleMargin,
  topTextMargin = "-20px",
  buttonLink,
  buttonText,
  bgPosition = "center center",
}) => (
  <React.Fragment>
    <Container
      fluid={true}
      css={css`
        position: relative;
        padding: 20px 0;
        margin-top: -80px;
      `}
    >
      <Row>
        <Col
          className="col-12 col-lg-6 order-3 order-lg-1"
          css={css`
            background-color: ${bgcolor1};
            min-height: 300px;
          `}
        />
        <Col
          className="col-12 d-block d-lg-none order-2 order-lg-2"
          css={css`
            background-color: ${bgcolor1};
          `}
        >
          <AngleCover
            rotation={rotation}
            botMargin={"-40px"}
            topMargin={"-40px"}
            backgroundColor={bgcolor1}
          />
        </Col>
        <Col
          className="col-12 col-lg-6 order-1 order-lg-3"
          css={css`
            background-color: ${bgcolor2};
            background-image: url(${image});
            background-size: cover;
            background-position: ${bgPosition};
            height: 550px;
          `}
        />
      </Row>
      <Row
        css={css`
          position: absolute;
          top: 150px;
          width: 100%;
          margin: 0;
        `}
      >
        <Col
          className="col-12 col-lg-4 offset-lg-1 col-xl-4 offset-xl-1 order-2 order-lg-1"
          css={css`
            z-index: 40;
            ${rotation === "-3deg" ? "margin-top: -45px;" : ""}
            @media (max-width: 992px) {
              ${rotation === "-3deg" ? "" : "margin-top: -60px;"}
            }
            @media (min-width: 992px) {
              ${rotation === "-3deg" ? "margin-top: 30px;" : ""}
            }
          `}
        >
          <Container>
            <Row>
              <Col>
                <h6
                  css={css`
                    font-family: Nunito;
                    font-weight: 600;
                    font-size: 1.8rem;
                  `}
                >
                  {header}
                </h6>
                <p
                  className={css`
                    font-family: Open Sans;
                    font-size: 1rem;
                  `}
                >
                  {body}
                </p>
                {buttonText && (
                  <Button outline={true} color={"dark"} href={buttonLink}>
                    {buttonText}
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          className="col-12 col-lg-6 order1 order-lg-2"
          css={css`
            min-height: 480px;
          `}
        />
      </Row>
    </Container>
    <AngleCover rotation={rotation} topMargin={bottomAngleMargin} />
  </React.Fragment>
)

export default UXContentContainer
